class ResponsiveImage extends HTMLElement {
    constructor () {
        super()
        const imgEl = this.querySelector('[data-src]')
        const sourceEls = this.querySelectorAll('[data-srcset]')

        if (imgEl) this.init(this, imgEl, sourceEls)
    }

    loadSrc (el) {
        el.setAttribute('src', el.getAttribute('data-src'))
        el.removeAttribute('data-src')
    }

    loadSrcsets (els) {
        els.forEach(els => {
            els.setAttribute('srcset', els.getAttribute('data-srcset'))
            els.removeAttribute('data-srcset')
        })
    }

    initImageObserver () {
        const config = {
            threshold: 0
        }

        window.imageObserver = new IntersectionObserver((entries, self) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (entry.target.hasAttribute('data-src')) this.loadSrc(entry.target)

                    const sourceEls = entry.target.parentNode.querySelectorAll('[data-srcset]')

                    if (sourceEls) this.loadSrcsets(sourceEls)

                    // Observer has been passed as self to our callback
                    self.unobserve(entry.target)
                }
            })
        }, config)
    }

    observeNewImage (el) {
        window.imageObserver.observe(el)
    }

    init (el, imgEl, sourceEls, lazyLoad = true) {
        // Check if native lazy-loading is supported, else use IntersectionObserver
        if (lazyLoad) {
            if ('loading' in window.HTMLImageElement.prototype) {
                this.loadSrc(imgEl)
                if (sourceEls) this.loadSrcsets(sourceEls)
            } else {
                // Init imageObserver as a global variable
                if (!window.imageObserver) this.initImageObserver()

                this.observeNewImage(imgEl)
            }
        }

        imgEl.addEventListener('load', () => {
            this.classList.add('is-loaded')
        })
    }
}

customElements.define('responsive-image', ResponsiveImage)
